import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import favicon64 from "../images/favicon64.png"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import social from "../images/social.png"

import GalleryPost from "../components/galleryPost"
import { convertPostToOldFormat } from "../lib/wpGraphQLQueryAdapter"

const ContentWrapper = styled.div`
  width: calc(100% - 230px);
  margin: 60px 30px 0 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (max-width: 1000px) {
    width: 600px;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
    width: 100%;
  }
`

const ArticleSideContainer = styled.div`
  width: 200px;
  height: fit-content;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 110px;
  left: 50px;

  @media (max-width: 1150px) {
    position: static;
  }

  @media (max-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

const ArticleDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticleTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticleAuthor = styled.p`
  font-size: 16px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0 0 15px 0;

  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
`

const ArticleDate = styled.p`
  font-size: 16px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0 0 15px 0;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const ArticleTagLabel = styled.p`
  font-size: 16px;
  color: black;
  font-family: "franklinGothic";
  margin: 15px 0 10px 0;

  @media (max-width: 700px) {
    margin: 0 0 10px 0;
    font-size: 14px;
  }
`

const ArticleTag = styled(Link)`
  width: 120px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #294973;
  margin: 0 0 10px 0;
  font-family: "franklinGothic";
  font-size: 14px;
  color: black;
  text-decoration: none;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 12px;
    width: 100px;
    min-height: 25px;
  }
`

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;

  @media (max-width: 1150px) {
    width: 100%;
    align-items: flex-start;
    margin: 50px 0 0 0;
  }

  @media (max-width: 700px) {
    margin: 40px 0 0 0;
  }
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 25px 0;
  width: 600px;

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 32px;
    margin: 0 0 20px 0;
  }
`

const ArticleImg = styled.img`
  width: 600px;
  margin: 0 0 30px 0;

  @media (max-width: 950px) {
    width: 100%;
  }
`

const ArticleContent = styled.div`
  width: 600px;
  font-family: "franklinGothic";
  font-size: 16px;
  color: black;
  line-height: 1.35;

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }

  a {
    color: black;
  }

  figure {
    margin: 30px 0 30px 0;
    width: 500px;
    max-width: 600px !important;

    @media (max-width: 950px) {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  li {
    margin-bottom: 10px;
  }
`

const Post = ({ data }) => {
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  
  const post = convertPostToOldFormat({ node: data.wpPost }).node;

  // we add in legacy data definitions because gallery posts depends on it
  data.wordpressPost = post
  console.log({ post })

  const articleTags =
    post.tags &&
    post.tags.map(tag => {
      return <ArticleTag to={`/${tag.slug}`}>{tag.name}</ArticleTag>
    })

  useEffect(() => {
    document.getElementById("articleContent").innerHTML =
      post.content

    document.getElementById(
      `articleTitle-${post.slug}`
    ).innerHTML = post.title

    let dateString = post.date
    let arr_date = dateString.slice(0, 10).split("-"),
      months = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

    setDate(
      months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]
    )

    let tempDiv = document.createElement("DIV")
    tempDiv.innerHTML = post.title
    setTitle(tempDiv.innerText)
  }, [])

  return (
    <>
      {post.featured_media ? (
        <Helmet
          link={[
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: `${favicon16}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: `${favicon32}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "64x64",
              href: `${favicon64}`,
            },
          ]}
        >
          <title>{title} - The Cannon</title>
          <meta property="og:title" content={`The Cannon`} />
          <meta
            property="og:description"
            content={post.excerpt}
          />
          <meta
            property="og:image"
            content={post.featured_media.source_url}
          />
          <meta
            property="og:image:url"
            content={post.featured_media.source_url}
          />
        </Helmet>
      ) : (
        <Helmet
          link={[
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: `${favicon16}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: `${favicon32}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "64x64",
              href: `${favicon64}`,
            },
          ]}
        >
          <title>{post.title} - The Cannon</title>
          <meta property="og:title" content={`The Cannon`} />
          <meta
            property="og:description"
            content={post.excerpt}
          />
          <meta property="og:image" content={social} />
          <meta property="og:image:url" content={social} />
        </Helmet>
      )}
      {post.categories[0].slug == "gallery" ? (
        <GalleryPost data={data} />
      ) : (
        <>
          <ContentWrapper>
            <ArticleSideContainer>
              <ArticleDetails>
                <ArticleAuthor>
                  By {post.author.name}
                </ArticleAuthor>
                <ArticleDate>{date}</ArticleDate>
              </ArticleDetails>
              <ArticleTagsWrapper>
                <ArticleTagLabel>Tags:</ArticleTagLabel>
                {articleTags}
              </ArticleTagsWrapper>
            </ArticleSideContainer>
            <Content>
              <Title id={`articleTitle-${post.slug}`}></Title>
              {post.featured_media && (
                <ArticleImg
                  src={post.featured_media.source_url}
                ></ArticleImg>
              )}
              <ArticleContent id="articleContent"></ArticleContent>
            </Content>
          </ContentWrapper>
        </>
      )}
    </>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      slug
      featuredImage {
        node {
          title
          sourceUrl
        }
      }
      author {
        node {
          name
        }
      }
      date
      tags {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          slug
        }
      }
    }
  }
`
