import React, { useState, useEffect } from "react"
import styled from "styled-components"

const ContentWrapper = styled.div`
  width: calc(100% - 230px);
  margin: 60px 30px 0 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (max-width: 1000px) {
    width: 600px;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
    width: 100%;
  }
`

const ArticleSideContainer = styled.div`
  width: 200px;
  height: fit-content;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 110px;
  left: 50px;

  @media (max-width: 1150px) {
    position: static;
  }

  @media (max-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

const ArticleDetails = styled.div`
  display: flex;
  flex-direction: column;
`


const ArticleAuthor = styled.p`
  font-size: 16px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0 0 15px 0;

  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
`

const ArticleDate = styled.p`
  font-size: 16px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0 0 15px 0;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;

  @media (max-width: 1150px) {
    width: 100%;
    align-items: flex-start;
    margin: 50px 0 0 0;
  }

  @media (max-width: 700px) {
    margin: 40px 0 0 0;
  }
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 25px 0;
  width: 600px;

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 32px;
    margin: 0 0 20px 0;
  }
`


const ArticleContent = styled.div`
  width: 600px;
  font-family: "franklinGothic";
  font-size: 16px;
  color: black;
  line-height: 1.35;

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }

  figure {
    margin: 30px 0 30px 0;
    width: 500px;
    max-width: 600px !important;

    @media (max-width: 950px) {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  h6 {
    font-size: 16px;
    margin: 0 0 10px 0;
    font-weight: 400;
    color: #294973;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  p {
    margin: 0 0 30px 0;
  }
`

const GalleryPost = ({ data }) => {
  const [date, setDate] = useState("")

  useEffect(() => {
    document.getElementById("articleContent").innerHTML =
      data.wordpressPost.content

    document.getElementById(
      `articleTitle-${data.wordpressPost.slug}`
    ).innerHTML = data.wordpressPost.title

    let dateString = data.wordpressPost.date
    let arr_date = dateString.slice(0, 10).split("-"),
      months = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

    setDate(
      months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]
    )
  }, [])

  return (
    <>
      <ContentWrapper>
        <ArticleSideContainer>
          <ArticleDetails>
            <ArticleAuthor>By {data.wordpressPost.author.name}</ArticleAuthor>
            <ArticleDate>{date}</ArticleDate>
          </ArticleDetails>
        </ArticleSideContainer>
        <Content>
          <Title id={`articleTitle-${data.wordpressPost.slug}`}></Title>
          <ArticleContent id="articleContent"></ArticleContent>
        </Content>
      </ContentWrapper>
    </>
  )
}

export default GalleryPost
